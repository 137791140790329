import React from "react";
import { ButtonProps } from "./types";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import withPermission from "../../permissions/withPermission";
import Loader from "../../components/Loader";

const Container = styled(Link)<ButtonProps>`
  align-items: baseline;
  border-width: 0;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: inherit;
  max-width: 100%;
  outline: none !important;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: ${(props) =>
    props.backgroundColor
      ? `#${props.backgroundColor}`
      : props.theme.button[props.$appearance || "default"].background[
          props.disabled ? "disabled" : "default"
        ]};
  color: ${(props) =>
    props.textColor
      ? `#${props.textColor}`
      : props.theme.button[props.$appearance || "default"].color[
          props.disabled ? "disabled" : "default"
        ]};
  border-radius: 3px;
  cursor: ${(props) =>
    props.disabled || props.$isFetching ? "not-allowed" : "pointer"};
  height: 32px;
  line-height: 32px;
  padding: 0;
  vertical-align: middle;
  width: ${(props) => (props.$shouldFitContainer ? "100%" : "auto")};
  &::-moz-focus-inner {
    border: 0;
    margin: 0;
    padding: 0;
  }
  &:hover {
    background: ${(props) =>
      props.theme.button[props.$appearance || "default"].background[
        props.disabled ? "disabled" : "hover"
      ]};
    color: ${(props) =>
      props.theme.button[props.$appearance || "default"].color[
        props.disabled ? "disabled" : "hover"
      ]};
    text-decoration: none;
  }
  &:active {
    background: ${(props) =>
      props.theme.button[props.$appearance || "default"].background[
        props.disabled ? "disabled" : "active"
      ]};
    color: ${(props) =>
      props.theme.button[props.$appearance || "default"].color[
        props.disabled ? "disabled" : "active"
      ]};
    text-decoration: none;
  }
  &:disabled {
    background: ${(props) =>
      props.theme.button[props.$appearance || "default"].background.disabled};
    color: ${(props) =>
      props.theme.button[props.$appearance || "default"].color.disabled};
    cursor: not-allowed;
  }

  @media (max-width: 420px) and (orientation: portrait),
    (max-height: 390px) and (orientation: landscape) {
    width: ${(props) => (props.isCompact ? "auto" : "100%")};
  }
`;

const InnerWrapper = styled.span`
  align-self: center;
  display: inline-flex;
  flex-wrap: nowrap;
  maxwidth: 100%;
  position: relative;
  width: 100%;
  justify-content: center;
`;

const Content = styled.span<{ isCompact: boolean }>`
  align-items: center;
  align-self: center;
  flex: 1 1 auto;
  margin: ${(props) => (props.isCompact ? "0 4px" : "0 16px")};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    appearance: "default",
    autoFocus: false,
    disabled: false,
    isFetching: false,
    isCompact: false,
    shouldFitContainer: false,
  };

  render() {
    const {
      appearance = "default",
      isExternalLink = false,
      children,
      disabled = false,
      isFetching = false,
      isCompact = false,
      shouldFitContainer = false,
      testId,
      onClick,
      to,
      ...rest
    } = this.props;

    const containerAs = to
      ? disabled
        ? "span"
        : isExternalLink
        ? "a"
        : Link
      : "button";

    let additionalProps: any = {};
    if (isExternalLink) {
      additionalProps.href = to;
    } else if (to) {
      additionalProps.to = to;
    }

    return (
      <Container
        {...rest}
        as={containerAs}
        isCompact={isCompact}
        {...additionalProps}
        onClick={disabled || isFetching ? undefined : onClick}
        data-testid={testId}
        $shouldFitContainer={shouldFitContainer}
        disabled={disabled}
        $isFetching={isFetching}
        $appearance={appearance}
      >
        <InnerWrapper>
          {isFetching && <Loader small={true} display="flex" />}
          {children && <Content isCompact={isCompact}>{children}</Content>}
        </InnerWrapper>
      </Container>
    );
  }
}

export default withPermission(Button);
