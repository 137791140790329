import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { defaultTheme, GlobalStyle } from "./themes";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-base-table/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { getApiClient } from "./services/apolloclient";
import { AuthProvider } from "./containers/AuthProvider";
import { config, loadConfig } from "./containers/Config";
import { setRenewTokenApiClient } from "./services/apolloclient/errorLink";

// try remove this
// Patch to silence the ResizeObserver loop limit exceeded issue
if (process.env.NODE_ENV === 'development') {
  const resizeObserverLoopErrSilence = () => {
    let resizeObserverErrHandled = false;

    const resizeObserverErr = window.console.error;
    window.console.error = (...args) => {
      if (args[0] && typeof args[0] === 'string' && args[0].includes('ResizeObserver loop limit exceeded')) {
        if (!resizeObserverErrHandled) {
          resizeObserverErrHandled = true;
          return;
        }
      }
      resizeObserverErr(...args);
    };
  };

  resizeObserverLoopErrSilence();
}

loadConfig().then(x => {
  setRenewTokenApiClient();
  ReactDOM.render(
    <>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        transition={Slide}
      />
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={getApiClient()}>
          <AuthProvider>
            <BrowserRouter>
              <ThemeProvider theme={defaultTheme}>
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </AuthProvider>
        </ApolloProvider>
      </I18nextProvider>
    </>,
    document.getElementById("root")
  );

  reportWebVitals();
});
