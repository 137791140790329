import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        allSitesPaging: relayStylePagination(),
        allSystemPaging: relayStylePagination(),
        allCustomerContactPaging: relayStylePagination(),
        allOperatorUserPaging: relayStylePagination(),
        allSharedContactPaging: relayStylePagination(),
        allCustomersSuperDealerPaging: relayStylePagination(),
        allSiteGuestsPaging: relayStylePagination(),
        allOperatorPaging: relayStylePagination(),
        systemArmingChecksPaging: relayStylePagination(),
        siteGuestsForUsersSitesPaging: relayStylePagination(),
        aILogPaging: relayStylePagination(),
        allTasks: relayStylePagination(),
        allTasksSearchFilter: relayStylePagination(),
        allCustomerContactsBySiteIdPaging: relayStylePagination(),
        allSystemCommissioningHistoryForSystem: relayStylePagination(),
        allSystemWalkTestPaging: relayStylePagination(),
        openSystemDecommissionRequestsPaging: relayStylePagination(),
        incidentReportsPaging: relayStylePagination(),
        mSUsPaging: relayStylePagination(),
      },
    },
  },
});

export default cache;
