import { createGlobalStyle } from "styled-components";
import { palette } from "./palette";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Gellix-Regular;
    src: url('fonts/Gellix-Regular.eot'); /* IE9 Compat Modes */
    src: url('fonts/Gellix-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('fonts/Gellix-Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('fonts/Gellix-Regular.woff') format('woff'), /* Pretty Modern Browsers */
        url('fonts/Gellix-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  } 
  @font-face {
    font-family: Gellix-SemiBold;
    font-weight: bold;
    src: url('fonts/Gellix-SemiBold.eot'); /* IE9 Compat Modes */
    src: url('fonts/Gellix-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('fonts/Gellix-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
        url('fonts/Gellix-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
        url('fonts/Gellix-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
  }
  html,
  body,
  p,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  dl,
  img,
  pre,
  form,
  fieldset {
    margin: 0;
    padding: 0;
  }
  img,
  fieldset {
    border: 0;
  }

  body,
  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  body {
    background-color: ${palette.white};
    color: ${palette.navy};
    font-family: Gellix, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857142857143;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    text-decoration-skip-ink: auto;
  }

  /* Default margins */
  p,
  ul,
  ol,
  dl,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  form,
  table {
    margin: 12px 0 0 0;
  }

  /* Links */
  a {
    color: #0052CC;
    text-decoration: none;
    &:hover {
      color: #0065FF;
      text-decoration: underline;
    }
    &:active {
      color: #0747A6;
    }
    &:focus {
      outline: 2px solid #4C9AFF;
      outline-offset: 2px;
    }
  }

  /* Headings */
  h1 {
    font-size: 2.0714285714285716em;
    font-style: inherit;
    line-height: 1.103448275862069;
    color: #172B4D;
    font-weight: 600;
    letter-spacing: -0.01em;
    margin-top: 40px;
  }
  h2 {
    font-size: 1.7142857142857142em;
    font-style: inherit;
    line-height: 1.1666666666666667;
    color: #172B4D;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin-top: 40px;
  }
  h3 {
    font-size: 1.4285714285714286em;
    font-style: inherit;
    line-height: 1.2;
    color: #172B4D;
    font-weight: 500;
    letter-spacing: -0.008em;
    margin-top: 28px;
  }
  h4 {
    font-size: 1.1428571428571428em;
    font-style: inherit;
    line-height: 1.25;
    color: #172B4D;
    font-weight: 600;
    letter-spacing: -0.006em;
    margin-top: 24px;
  }
  h5 {
    font-size: 1em;
    font-style: inherit;
    line-height: 1.1428571428571428;
    color: #172B4D;
    font-weight: 600;
    letter-spacing: -0.003em;
    margin-top: 16px;
  }
  h6 {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #172B4D;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;
  }

  /* Lists */
  ul,
  ol,
  dl {
    padding-left: 25px;
  }
  [dir='rtl']ul,
  [dir='rtl']ol,
  [dir='rtl']dl {
    padding-left: 0;
    padding-right: 40px;
  }

  dd,
  dd + dt,
  li + li {
    margin-top: 4px;
  }
  ul ul:not(:first-child),
  ol ul:not(:first-child),
  ul ol:not(:first-child),
  ol ol:not(:first-child) {
    margin-top: 4px;
  }

  /* remove top margin for first element */
  p:first-child,
  ul:first-child,
  ol:first-child,
  dl:first-child,
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  blockquote:first-child,
  pre:first-child,
  form:first-child,
  table:first-child {
    margin-top: 0;
  }

  /* Quotes */
  blockquote,
  q {
    color: inherit;
  }
  blockquote {
    border: none;
    padding-left: 40px;
  }
  [dir='rtl'] blockquote {
    padding-left: 0;
    padding-right: 40px;
  }

  blockquote::before,
  q::before {
    content: "\\201C";
  }

  blockquote::after,
  q::after {
    content: "\\201D";
  }

  blockquote::before {
    float: left;
    /* to keep the quotes left of any child elements like blockquote > p */
    margin-left: -1em;
    text-align: right;
    width: 1em;
  }
  [dir='rtl'] blockquote::before {
    float: right;
    margin-right: -1em;
    text-align: left;
  }

  blockquote > :last-child {
    display: inline-block; /* so the quotes added via pseudos follow it immediately. */
  }

  /* Other typographical elements */
  small {
    font-size: 0.7857142857142857em;
    font-style: inherit;
    line-height: 1.4545454545454546;
    color: #6B778C;
    font-weight: 700;
    margin-top: 16px;
    font-weight: normal;
  }

  code,
  kbd {
    font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
  }

  var,
  address,
  dfn,
  cite {
    font-style: italic;
  }

  abbr {
    border-bottom: 1px #ccc dotted;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead,
  tbody,
  tfoot {
    border-bottom: 2px solid #DFE1E6;
  }

  td,
  th {
    border: none;
    padding: 4px 8px;
    text-align: left;
  }

  th {
    vertical-align: top;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  caption {
    font-size: 1.4285714285714286em;
    font-style: inherit;
    line-height: 1.2;
    color: #172B4D;
    font-weight: 500;
    letter-spacing: -0.008em;
    margin-top: 28px;
    margin-bottom: 8px;
    text-align: left;
  }

  /* IE11 doesn't support <template> elements which shouldn't be displayed */
  template {
    display: none;
  }

  /* IE11 and some older browsers don't support these elements yet and treat them as display: inline; */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block;
  }

  /* Suppress the ugly broken image styling in Firefox */
  @-moz-document url-prefix() {
    img {
      font-size: 0;
    }
    img:-moz-broken {
      font-size: inherit;
    }
  }
  /* style for toast */
  .Toastify__toast-container {
    width: auto;
  }
  
  .Toastify__toast {
    padding: 20px 32px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    direction: ltr;
  }

  .Toastify__toast--error {
    background: #9B2242;
  }
  
  .Toastify__toast--success {
    width: 400px;
    padding: 40px 56px 32px;
    font-size: 14px;
    line-height: 32px;
    font-style: normal;
    background: #35D38C;
    
    &:before {
      content: "Completed!";
      position: absolute;
      top: 16px;
      left: 56px;     
      font-weight: 600;
    }
    
    &:after {
      content: "\\2714";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px;
      left: 16px;
      width: 24px;
      height: 24px;
      color: #00875A;
      font-weight: bold;
      font-size: 12px;
      border-radius: 50%;
      background-color: #ffffff;
      overflow: hidden;
    }
    
    .Toastify__close-button--success {
      transform: translateY(-15px) translateX(23px);
    }
  }
  /** Preloader styles * ==================== */
  #live-view-preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000024;
    z-index: 3000;
  }

  .contpre {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .cssload-container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .cssload-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
  }

  .cssload-bouncywrap {
    position: relative;
  }

  .cssload-cssload-dotcon {
    display: block;
    float: left;
    width: 69px;
    position: absolute;
  }

  .cssload-dc1 {
    animation: bouncy1 1.73s infinite;
    -o-animation: bouncy1 1.73s infinite;
    -ms-animation: bouncy1 1.73s infinite;
    -webkit-animation: bouncy1 1.73s infinite;
    -moz-animation: bouncy1 1.73s infinite;
    left: -55px;
  }

  .dc2 {
    animation: bouncy2 1.73s infinite;
    -o-animation: bouncy2 1.73s infinite;
    -ms-animation: bouncy2 1.73s infinite;
    -webkit-animation: bouncy2 1.73s infinite;
    -moz-animation: bouncy2 1.73s infinite;
    left: 0;
  }

  .dc3 {
    animation: bouncy3 1.73s infinite;
    -o-animation: bouncy3 1.73s infinite;
    -ms-animation: bouncy3 1.73s infinite;
    -webkit-animation: bouncy3 1.73s infinite;
    -moz-animation: bouncy3 1.73s infinite;
    left: 55px;
  }

  .cssload-dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #fff;
  }

  @keyframes bouncy1 {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
              transform: translate(0px, 0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translate(0px, 0px) rotate(180deg);
              transform: translate(0px, 0px) rotate(180deg);
    }
    100% {
      -webkit-transform: translate(55px, 0px) rotate(-180deg);
              transform: translate(55px, 0px) rotate(-180deg);
    }
  }

  @-webkit-keyframes bouncy1 {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translate(0px, 0px) rotate(180deg);
    }
    100% {
      -webkit-transform: translate(55px, 0px) rotate(-180deg);
    }
  }

  @keyframes bouncy2 {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(-55px);
              transform: translateX(-55px);
    }
    100% {
      -webkit-transform: translateX(-55px);
              transform: translateX(-55px);
    }
  }

  @-webkit-keyframes bouncy2 {
    0% {
      -webkit-transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(-55px);
    }
    100% {
      -webkit-transform: translateX(-55px);
    }
  }

  @keyframes bouncy3 {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    100% {
      -webkit-transform: translateX(-55px);
              transform: translateX(-55px);
    }
  }

  @-webkit-keyframes bouncy3 {
    0% {
        -webkit-transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(0px);
    }
    100% {
        -webkit-transform: translateX(-55px);
    }
  }

  // preventing Webpack Dev Server's error overlay from being displayed
  iframe#webpack-dev-server-client-overlay{display:none!important}
`;

export default GlobalStyle;
